// Navbar

const nav = document.querySelector(".navbar");
const navToggle = document.querySelector(".hamburger");

navToggle.addEventListener("click", () => {
    const visibility = nav.getAttribute("data-visible");

    if (visibility === "false") {
        nav.setAttribute("data-visible", true);
        nav.setAttribute("aria-expanded", true);
        navToggle.setAttribute("aria-expanded", true);
    }
    else if (visibility === "true") {
        nav.setAttribute("data-visible", false);
        nav.setAttribute("aria-expanded", false);
        navToggle.setAttribute("aria-expanded", false);
    }
});

// Lightbox

const lightboxBg = document.createElement('div');
const images = document.querySelectorAll('.home-gallery__img');
const lightboxClose = document.querySelector('.lb-close');

lightboxBg.id = "lightboxBg";
document.body.appendChild(lightboxBg);

images.forEach(image => {
    image.addEventListener('click', () => {
        lightboxBg.classList.add('active');
        lightboxClose.setAttribute("data-visible", true);

        const lightboxImg = document.createElement('img');

        lightboxImg.src = image.src;
        lightboxImg.id = "lightboxImg";

        while (lightboxBg.firstChild) {
            lightboxBg.removeChild(lightboxBg.firstChild);
        }

        lightboxBg.appendChild(lightboxImg);
    })
})

lightboxBg.addEventListener('click', () => {
    lightboxBg.classList.remove('active');
    lightboxClose.setAttribute("data-visible", false);
})

lightboxClose.addEventListener('click', () => {
    lightboxBg.classList.remove('active');
    lightboxClose.setAttribute("data-visible", false);
})